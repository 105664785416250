export default class Message {
    constructor(id, origin, type, payload, event) {
        this.id = id;
        this.origin = origin;
        this.type = type;
        this.payload = payload;
        this.event = event;
    }

    toString() {
        return JSON.stringify(this);
    }

    toJSON() {
        const message = {...this};
        delete message['event'];
        return message;
    }

    static fromEvent(event) {
        // Generate an Message instance from an event.
        // it operates with 2 modes:
        // * Messages with `type` in data
        // * Messages without `type` in data
        const { data, origin } = event;
        return new this(
            data.id,
            // Compatibility measure, use the origin of the data of the event
            // if it has a type, else une the origin of the event
            data.origin || origin,
            // Default the type to 'unknown'
            data.type || 'unknown',
            // Compatibility, use the whole data for messages without type
            data.payload || data,
            event
        );
    }
}