import Message from './message';

const rid = () => (Math.random() * 0x10000000).toString(16);

export default class WeezSocket {
    constructor(iframe) {
        this.window = window.self !== window.top ? iframe.parent : iframe.contentWindow;
        this.waiting_answers = {};
    }

    send(message, { needs_answer = false, timeout = 500 } = {}) {
        if (needs_answer) {
            return new Promise((resolve, reject) => {
                const id = rid();
                const newMessage = new Message(id, message.origin, message.type, message.payload, message.event);
                const rejectTimeout = setTimeout(() => reject(), timeout);
                this.waiting_answers[id] = result => {
                    clearTimeout(rejectTimeout);
                    resolve(result);
                    delete this.waiting_answers[id];
                };
                this.window.postMessage(newMessage, '*');
            });
        }
        /*TODO changer le message pour qu'il soit au format message*/
        this.window.postMessage(message, '*');
    }

    register(handler) {
        const onMessage = (event) => {
            // filter messages directed to this iframe
            if (event.source === this.window.parent) {
                handler(event, this.window);
            }
        };
        window.addEventListener('message', onMessage);
        return () => window.removeEventListener('message', onMessage);
    }

    listen() {
        window.addEventListener('message', (event) => {
            // filter messages directed to this iframe
            if (event.source === this.window.parent) {
                let message = Message.fromEvent(event);
                if (message.id && (message.id in this.waiting_answers)) {
                    // we are answering to the iframe thanks to a promise
                    this.waiting_answers[message.id](message);
                    return;
                }
            }
        });
    }
}

export class NullSocket {
    send() { }
    listen() { }
    register(handler) {
        return handler;
    }
}
