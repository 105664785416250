import Widget from '../common/widget';
import oldWidget from './oldWeez';
import {unknownHandle} from './handlers';
import WeezSocket from '../common/weezSocket';
import { getSourceWithParentParams } from '../utils/utils';

export default class WidgetTicket extends Widget {
    static handles = [
        unknownHandle,
    ];

    static type = 'ticket';

    getSettings() {
        const data = this.getDataset();
        // TODO remove when all widget will be deleted from Weezevent
        let out = {
            'id': data.id === 'multi' ? 'weezmulti' : 'weezuniq' + data.id,
            'src': data.src + '&v=2&neo=1',
            'resize': data.resize,
            'height': data.height,
            'width': (data.width_auto && data.width_auto === '1') ? '100%' : data.width,
            'hidePoweredBy': (data.nopb) ? data.nopb : 0,
            'frameborder': '0',
            'allowfullscreen': 'true',
            'scrolling': 'auto'
        };
        // If 1 be sure that integration code has both values to 1
        if(out['resize'] === '1') {
            out['src'] += '&jsintegration=1';
        }
        return out;
    }

    getAttributes() {
        return {
            src: getSourceWithParentParams(this.getDataset().src),
        };
    }

    render() {
        this.iframe = this.buildIframe();
        this.inject();

        oldWidget(this.getSettings(), this.iframe, this.tag);
        this.socket = new WeezSocket(this.iframe);
    }
}
