import Widget from '../common/widget';
import { cleanUrlParams, parentUrl, resizeHandle } from './handlers';
import WeezSocket from '../common/weezSocket';
import { getSourceWithParentParams } from '../utils/utils';

export default class WidgetGadget extends Widget {
    static type = 'gadget';
    static handles = [
        cleanUrlParams,
        resizeHandle,
        parentUrl,
    ];

    getAttributes() {
        const data = this.getDataset();
        const src = getSourceWithParentParams(data.src);

        return {
            'src': src,
            'height': data.height,
            'resize': data.resize,
            'width': (data.width_auto && data.width_auto === '1') ? '100%' : data.width,
            'frameborder': '0',
            'allowfullscreen': 'true',
            'scrolling': 'auto',
            'allow': 'payment',
        };
    }
    
    findPos (obj) {
        let pos = [0, 0];
        if (obj.offsetParent) {
            pos[0] += obj.offsetLeft;
            pos[1] += obj.offsetTop;
            while(obj.offsetParent) {
                obj = obj.offsetParent;
                pos[0] += obj.offsetLeft;
                pos[1] += obj.offsetTop;
            }
            return pos;
        }
    }
    
    scrollToWidget() {
        let params = new URLSearchParams(window.location.search);
        // we will wait that the page is loaded before scrolling
        window.addEventListener('load', () => {
            let scrollToWidget = params.get('scrollToWeezWidget');
            if (scrollToWidget === '1') {
                let pos = this.findPos(this.iframe);
                // we add an offset of 10
                window.scrollTo({top: pos[1] - 10, left: pos[0], behavior: 'smooth'});
                this.cleanUrlParams();
            } 
        });
    }

    cleanUrlParams() {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);
        params.delete('scrollToWeezWidget');
        url.search = params;
        const newUrl = url.toString();
        window.history.pushState({path:newUrl}, '', newUrl);
    }

    render() {
        super.render();
        this.socket = new WeezSocket(this.iframe);
        this.scrollToWidget();
    }
}
