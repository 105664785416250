

export const oldBindEvent = (settings, iframe ) => {

    function catchEvent(event) {
        if(event.source !== iframe.contentWindow) {
            return;
        }

        if (typeof event.data === 'object' && event.data.message === 'WEEZ_GAT_QUESTION') {
            hideLink(event.data.id);
            sendTrackerIds(event.data.id, event.source);
        } else if (typeof event.data === 'string') {
            let res = event.data.split('-');

            // old widget from Neo do not have IFrame ID ( cause of multiwidget can have the same id )
            if(res[0] === iframe.id || iframe.id === '') {
                hideLink(res[0]);
                if (settings.resize === '1') {
                    if (event.data.indexOf('SCROLLIFRAME') === 0) {
                        let pos = findPos(iframe);
                        window.scrollTo(pos[0], pos[1]);
                    } else if ((res[0] === 'weezmulti' || res[0].indexOf('weezuniq') === 0) && iframe) {
                        let element = iframe;
                        let height = res[1] + 'px';
                        // iFrame height

                        element.height = height;
                        // Override iframe CSS if needed
                        element.style.height = height;
                    } else if (event.data.indexOf('SCROLLFORM') === 0) {
                        let iframeId = '';
                        if (iframe !== null) {
                            iframeId = iframe;
                        }
                        if (iframeId !== '') {
                            let internalOffsetHeight = res[1];
                            let iframeContentHeight = parseFloat(res[2]);
                            let iframeHeight = parseFloat(document.getElementById(iframeId).scrollHeight);
                            let offset = 0;
                            if (iframeHeight >= iframeContentHeight) {
                                offset = internalOffsetHeight;
                            }
                            window.scrollTo(0, parseFloat(elmYPosition(iframeId)) + parseFloat(offset));
                        }
                    }
                }
            }
        }
    }

    function elmYPosition(eID) {
        let elm = document.getElementById(eID);
        let y = elm.offsetTop;
        let node = elm;
        while (node.offsetParent && node.offsetParent !== document.body) {
            node = node.offsetParent;
            y += node.offsetTop;
        }
        return y;
    }

    function findPos (obj) {
        let pos = [0, 0];
        if (obj.offsetParent) {
            pos[0] += obj.offsetLeft;
            pos[1] += obj.offsetTop;
            while(obj.offsetParent) {
                obj = obj.offsetParent;
                pos[0] += obj.offsetLeft;
                pos[1] += obj.offsetTop;
            }
            return pos;
        }
    }

    function sendTrackerIds (id, widIframe) {
        if (typeof ga !== 'function') {
            return;
        }
        let widData = {message: 'WEEZ_GAT_ANSWER'};
        ga(function () {
            ga.getAll().some(function (tracker) {
                if (tracker.get('trackingId') === id) {
                    widData.clientId = tracker.get('clientId');
                    widData.trackingId = id;
                }
                return tracker.get('trackingId') === id;
            });
            widIframe.postMessage(widData, '*');
        });
    }

    function  hideLink (id) {
        let link = document.getElementById('link' + id);
        if (link) {
            if (link.parentNode !== null) {
                link.parentNode.removeChild(link);
            }
        }
    }

    if (!window.addEventListener) {
        window.attachEvent('onmessage', catchEvent);
    } else {
        window.addEventListener('message', catchEvent, false);
    }
};


function oldWidget(settings, iframe, tag) {
    let widgets = {

        'display': function (link, weezLink) {
            tag.parentNode.insertBefore(link, tag);
            iframe.parentNode.appendChild(weezLink);
            tag.parentNode.removeChild(tag);
        },

        'setIframeParams': function () {
            for (let key in settings) {
                if (!settings[key]) continue;
                iframe.setAttribute(key, settings[key]);
            }
        },

        'styleLink': function (weezLink) {
            let css = tag.style.cssText;
            css += 'float: right !important;';
            css += 'margin: 10px 0 15px 0 !important;';
            css += 'font-size: 11px !important;';
            css += 'font-family: Arial, sans-serif !important;';
            css += 'text-decoration: none !important;';
            css += 'color: #333333 !important;';
            weezLink.style.cssText = css;
            weezLink.href = tag.href;
        },

        'styleIframe': function () {
            let css = 'box-sizing:initial; -moz-box-sizing:initial; -webkit-box-sizing:initial;';
            iframe.style.cssText = css;
        },

        'getLink': function () {
            let a = document.createElement('a');
            let text = 'Cliquer ici pour acheter des billets / Click here to buy tickets';
            let linkText = document.createTextNode(text);
            a.appendChild(linkText);
            a.id = 'link' + settings['id'];
            a.title = text;
            a.style.display = 'none';
            a.href =  tag.attributes['data-src'].value;

            setTimeout(function() {
                a.style.display = 'block';
            }, 5000);
            return a;
        },

        'init': function () {
            if (tag.className.indexOf('rendered') !== -1) {
                return;
            }
            let link = this.getLink();
            let src = settings.src;

            if(iframe.contentWindow) {
                iframe.contentWindow.location.replace(src);
            } else {
                // there is not much we can do
                iframe.src = src;
            }

            this.setIframeParams();

            let weezLink = document.createElement('a');

            this.styleIframe();
            this.styleLink(weezLink);

            oldBindEvent( settings, iframe );
            tag.classList.add('rendered');
            weezLink.innerHTML = (settings.hidePoweredBy !== '1') ? 'Powered by Weezevent' : '';
            this.display(link, weezLink);
        },
    };
    return widgets.init();
}

export default oldWidget;
