/** Merge the query parameters from the source link (the href from the a)
 * with the parameters of the source window
 *
 * Caution:
 * - It will erase duplicate parameters.
 * - It will replace the parameters explicitely given in the href by the
 *   parameters of the parent page
 * - It will silently fail
 *
 * ex in http://localhost/?foo=bar&abc=ignore&abc=keep
 * getSourceWithParentParams('http://localhost/?def=123&foo=baz')
 *
 * http://localhost/?def=123&foo=bar&abc=keep
 */
export function getSourceWithParentParams(source, otherParams={}) {
    try {
        // TODO: find polyfill for IE
        // Extract the querystring from the source URL
        let originalSource = new URL(source);
        let originalParams = new URLSearchParams(originalSource.searchParams);
        // Extract the querystring from the window URL
        let urlParams = new URLSearchParams(window.location.search);

        // Get framgments starting by "weez_" and create a string with them to add it to originalSource
        const hash_parameters = window.location.hash.split('#').filter(fragment => fragment.startsWith('weez_'));

        // Add params from parent window to the source
        for(let [ key, value ] of originalParams.entries()) {
            urlParams.set(key, value);
        }

        // Add params from localStorage to the source
        for(let key of AUTHORIZED_PARAMS) {
            const value = getLocalWithExpiry(key);
            if(value) {
                urlParams.set(key, value);
            }
        }

        // Add extra params from the arguments
        for(let [ key, value ] of Object.entries(otherParams)) {
            urlParams.set(key, value);
        }

        originalSource.search = urlParams;
        if (hash_parameters.length) {
            originalSource.hash += '#' + hash_parameters.join('#');
        }
        return originalSource.toString();
    } catch(e) {
        // Catch whatever because why not and fail silently
    }
    return source;
}

const AUTHORIZED_PARAMS = [
    'utm_source',
    'utm_campaign',
    'utm_medium',
    'utm_term',
    'utm_content',
    'o',
];

export function setLocalWithExpiry(key, value, ttl=30*24*60*60*1000) {
    if(AUTHORIZED_PARAMS.includes(key)) {
        // `item` is an object which contains authorized value
        // as well as the time when it's supposed to expire
        const item = {
            expiry: Date.now() + ttl,
            value: value
        };
        localStorage.setItem(`wz_${key}`, JSON.stringify(item));
    }
}

export function getLocalWithExpiry(key) {
    const itemStr = localStorage.getItem(`wz_${key}`);

    // if the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);

    // compare the expiry time of the item with the current time
    if (Date.now() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(`wz_${key}`);
        return null;
    }
    return item.value;
}

export function needHackForChrome108() {
    const test = navigator.userAgent.match(/chrom(?:e|ium)\/(\d+)\./i);// eslint-disable-line
    if (test && !window.location.host.includes('weezevent.com')) {
        return (parseInt(test[1], 10) === 108);
    }
    return false;
}
