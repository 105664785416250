import Widget from '../common/widget';
import {Handle} from '../common/handler';
import Socket from '../common/weezSocket';

const pingPongHandle = new Handle( 'PingPong', ({ type, origin, payload, event }, widget) => { // eslint-disable-line no-unused-vars
    console.log('HOST: ping/pong', payload);
    if(payload === 'ping') {
        const socket = new Socket(widget.iframe);
        socket.send('PONG');
    }
});


export default class WidgetPingPong extends Widget {
    static handles = [
        pingPongHandle,
    ];

    static type = 'pingPong';
}
