import Widget from '../common/widget';
import Socket from '../common/weezSocket';

const TARGET_SEND_VALUES = 'target-send-values';

export default class WidgetGroot extends Widget {
    static handles = [
        // React to change in dimensions
        {type: 'target-change-height', callback: ({ payload }, widget) => {
            widget.iframe.style.height = `${payload.height ? payload.height : widget.iframe.height}px`;
        }},
        // When groot is ready, it sends a target-ask-values message and
        // expects a TARGET_SEND_VALUES response with the page and referrer
        {type: 'target-ask-values', callback: (message, widget) => {
            // notify parent window is ready
            const socket = new Socket(widget.iframe);
            socket.send({
                type: TARGET_SEND_VALUES,
                page: window.location.href,
                referrer: document.referrer,
            });
        }},
    ];

    static type = 'groot';

    getAttributes() {
        const data = this.getDataset();
        return {
            // src is data-iframe-src or defaults to the href of the tag
            src: data.iframeSrc || this.tag.href,
            width: data.iframeWidth || '100%',
            scrolling: 'no',
            frameBorder: '0',
        };
    }
}
