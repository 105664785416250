import {Handle} from '../common/handler';
import Message from '../common/message';

// Resize automatically
export const resizeHandle = new Handle('resize', ({ type, origin, payload, event }, widget) => { // eslint-disable-line no-unused-vars
    if(widget.iframe.getAttribute('resize') === '1') {
        widget.iframe.height = payload.height;
        // Retrocomp if we mix old / new widgets (ex: multi event)
        if(widget.iframe.style['height']) {
            widget.iframe.style['height'] = `${payload.height}px`;
        }
    }
});

// get parent url
export const parentUrl = new Handle('parent-url', ({ id, type, origin, payload, event }, widget) => { // eslint-disable-line no-unused-vars
    if (!id) {
        console.error('Id is missing but it\'s required');
        return;
    }
    const message = new Message(id, type, 'piglet', { parentUrl : window.location.href }, event);
    widget.iframe.contentWindow.postMessage(message.toJSON(), '*');
});

// clean url params
export const cleanUrlParams = new Handle('clean-url-params', ({ id, type, origin, payload, event }, widget) => { // eslint-disable-line no-unused-vars
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const { paramsKeys = [] } = payload;
    paramsKeys.forEach(key => {
        params.delete(key);
    });
    url.search = params;
    const newUrl = url.toString();
    window.history.pushState({path:newUrl}, '', newUrl);
});