import {Handle} from '../common/handler';

let old_resize = /^weezuniq\d+-\d+(\.\d+)?$/;

// Resize on old message types
export const unknownHandle = new Handle('unknown', ({ type, origin, payload, event }, widget) => { // eslint-disable-line no-unused-vars
    // old resize
    // For some reason the size message on firefox has a .xxx on it
    if(old_resize.test(payload)) {
        let [, height] = payload.split('-');
        widget.iframe.height = Math.ceil(height);
    }
});

// Resize automatically
export const resizeHandle = new Handle('resize', ({ type, origin, payload, event }, widget) => { // eslint-disable-line no-unused-vars
    if(widget.iframe.getAttribute('resize') === '1') {
        widget.iframe.height = payload.height;
        // Retrocomp if we mix old / new widgets (ex: multi event)
        if(widget.iframe.style['height']) {
            widget.iframe.style['height'] = `${payload.height}px`;
        }
    }
});

// Set the ID of the iframe
export const setWidgetIdHandle = new Handle('filteredWidgetClick', ({ type, origin, payload, event }, widget) => { // eslint-disable-line no-unused-vars
    widget.iframe.id = 'weezuniq' + payload.id;
});

// clean url params
export const cleanUrlParams = new Handle('clean-url-params', ({ id, type, origin, payload, event }, widget) => { // eslint-disable-line no-unused-vars
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const { paramsKeys = [] } = payload;
    paramsKeys.forEach(key => {
        params.delete(key);
    });
    url.search = params;
    const newUrl = url.toString();
    window.history.pushState({path:newUrl}, '', newUrl);
    localStorage.removeItem('wz_resale_uuid');
    window.location.reload();
});
