// Test widget
import WidgetPingPong from '../test/widgetPingPong';
import WidgetTicket from '../ticket/widgetTicket';
import WidgetNeo from '../ticket/widgetNeo';
import WidgetGroot from '../target/widgetGroot';
import WidgetGadget from '../pay/widgetGadget';

class WidgetManager {
    constructor() {
        this.widgets = {};
        this.runtime = [];
    }

    register(Widget) {
        this.widgets[Widget.type] = Widget;
    }

    fromTag(tag) {
        const { dataset: { type }} = tag;
        if(!this.widgets[type]) {
            return console.warn(`[Weezevent] No registered widget for type ${type}`);
        }
        const Widget = this.widgets[type];

        let widget = new Widget(tag);
        widget.render();
        this.runtime.push(widget);
    }
}

const manager = new WidgetManager();

manager.register(WidgetTicket);
manager.register(WidgetNeo);
manager.register(WidgetGadget);
manager.register(WidgetPingPong);
manager.register(WidgetGroot);


export default manager;
